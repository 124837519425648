import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import {
  DataTable,
  TableContainer,
  TableBatchActions,
  TableBatchAction,
  TableToolbar,
  Table,
} from "carbon-components-react";
import { CardGroup, MiniCard } from "gatsby-theme-carbon/src/components/MiniCard";
import { InlineNotification } from "gatsby-theme-carbon";
import { getHeaderData, getQuestionsData } from "../data/";
import { presetAddedQuestions, formatQuestionsData, formatTaxonomies } from "../util";
import {
  QuestionsToolbarContent,
  QuestionsPagination,
  QuestionPreviewModal,
  QuestionsTableHead,
  QuestionsTableBody,
} from "../components";
import { DocumentAdd16, Document32 } from "@carbon/icons-react";

const headerData = getHeaderData("home");

export default function Questions({ data }) {
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [filteredRowsData, setFilteredRowsData] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsData, setRowsData] = useState([]);
  const [previewing, setPreviewing] = useState(null);
  const [previewQuestionObj, setPreviewQuestionObj] = useState(null);
  const [addedQuestions, setAddedQuestions] = useState(presetAddedQuestions());
  const [categoriesData, setCategoriesData] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);

  useEffect(() => {
    formatTaxonomies(data, setCategoriesData, setKeywordsData);
  }, [data]);

  useEffect(() => {
    if (categoriesData.length > 0 && keywordsData.length > 0) {
      if (
        data.allNodeQuestion &&
        data.allNodeQuestion.edges &&
        data.allNodeQuestion.edges.length > 0
      ) {
        setQuestionsData(getQuestionsData(data.allNodeQuestion, data.site));
      }
    }
  }, [categoriesData, keywordsData, data]);

  useEffect(() => {
    if (questionsData.length > 0) {
      let rows_data = formatQuestionsData(
        questionsData,
        categoriesData,
        keywordsData,
        setPreviewing
      );
      setRowsData(rows_data);
      setFilteredRowsData(rows_data);
    }
  }, [questionsData, categoriesData, keywordsData]);

  useEffect(() => {
    if (rowsData.length > 0) {
      let filtered = [];
      if (categoryFilters.length > 0 && categoryFilters.length < categoriesData.length) {
        filtered = rowsData.filter((question) => {
          return categoryFilters.some((cat_id) => {
            return question.category_ids.includes(cat_id);
          });
        });
      } else {
        filtered = rowsData;
      }
      let added_ids = addedQuestions.map((q) => q.id);
      setFilteredRowsData(
        filtered.filter((question) => !added_ids.includes(question.id))
      );
    }
  }, [categoryFilters, addedQuestions, rowsData, categoriesData]);

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      if (addedQuestions.length === 0) {
        localStorage.removeItem("addedQuestions");
      } else {
        let added_str = JSON.stringify(addedQuestions);
        localStorage.setItem("addedQuestions", added_str);
      }
    }
  }, [addedQuestions]);

  useEffect(() => {
    if (previewing) {
      let previewing_question = filteredRowsData.find(
        (question) => question.id === previewing
      );
      if (previewing_question) {
        setPreviewQuestionObj(previewing_question);
      } else {
        setPreviewQuestionObj(null);
      }
    } else {
      setPreviewQuestionObj(null);
    }
  }, [previewing, filteredRowsData]);

  function toggleCategoryFilter(checked, id) {
    if (checked) {
      setCategoryFilters([...categoryFilters, id]);
    } else {
      setCategoryFilters(categoryFilters.filter((cat_id) => cat_id !== id));
    }
    setCurrentPage(1);
  }

  function onPreviewSubmit(e) {
    if (previewQuestionObj !== null) {
      setAddedQuestions([
        ...addedQuestions,
        { id: previewQuestionObj.id, order: previewQuestionObj.order },
      ]);
      setPreviewing(null);
    }
  }

  return (
    <>
      <DataTable
        rows={filteredRowsData}
        headers={headerData}
        isSortable
        render={({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          getBatchActionProps,
          onInputChange,
          selectedRows,
        }) => {
          return (
            <>
              {addedQuestions.length === 0 && (
                <InlineNotification className="empty-test-notification">
                  Look for questions you want to add to your custom test and click{" "}
                  <strong>"Add to test"</strong> either from the batch actions at the top
                  of the table or from the preview dialog of each question.
                </InlineNotification>
              )}
              {addedQuestions.length > 0 && (
                <CardGroup>
                  <MiniCard
                    title={
                      <>
                        <small>Custom Test:</small>
                        <strong>
                          {addedQuestions.length === 1
                            ? `1 question`
                            : `${addedQuestions.length} questions`}
                        </strong>
                      </>
                    }
                    href="/custom-test"
                    actionIcon="arrowRight"
                    className="custom-test-minicard"
                  >
                    <Document32 />
                  </MiniCard>
                </CardGroup>
              )}
              <div className="bx--row">
                <TableContainer id="questions-data-table">
                  <TableToolbar
                    onChange={(e) => {
                      setCurrentPage(1);
                    }}
                  >
                    <TableBatchActions {...getBatchActionProps()}>
                      <TableBatchAction
                        tabIndex={getBatchActionProps().shouldShowBatchActions ? 0 : -1}
                        renderIcon={DocumentAdd16}
                        onClick={(e) => {
                          let selected_questions = selectedRows.map((a_row) => {
                            let a_question = rowsData.find(
                              (question) => question.id === a_row.id
                            );
                            return {
                              id: a_question.id,
                              order: a_question.order,
                            };
                          });
                          setAddedQuestions([...addedQuestions, ...selected_questions]);
                          getBatchActionProps().onCancel();
                        }}
                      >
                        Add to test
                      </TableBatchAction>
                    </TableBatchActions>
                    <QuestionsToolbarContent
                      getBatchActionProps={getBatchActionProps}
                      onInputChange={onInputChange}
                      categoryFilters={categoryFilters}
                      toggleCategoryFilter={toggleCategoryFilter}
                      setCategoryFilters={setCategoryFilters}
                      categoriesData={categoriesData}
                    />
                  </TableToolbar>
                  <Table>
                    <QuestionsTableHead
                      headers={headers}
                      getSelectionProps={getSelectionProps}
                      getHeaderProps={getHeaderProps}
                      withSelection={true}
                    />
                    <QuestionsTableBody
                      rows={rows}
                      getSelectionProps={getSelectionProps}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      getRowProps={getRowProps}
                      withSelection={true}
                    />
                  </Table>
                  {filteredRowsData.length > 0 && (
                    <QuestionsPagination
                      setCurrentPage={setCurrentPage}
                      setPageSize={setPageSize}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      totalItems={rows.length}
                    />
                  )}
                  <QuestionPreviewModal
                    previewQuestionObj={previewQuestionObj}
                    setPreviewing={setPreviewing}
                    previewing={previewing}
                    onPreviewSubmit={onPreviewSubmit}
                    primaryButtonText="Add to test"
                  />
                </TableContainer>
              </div>
            </>
          );
        }}
      />
    </>
  );
}

export const query = graphql`
  {
    allSitePage(filter: { path: { eq: "/questions/" } }) {
      edges {
        node {
          id
          path
          fields {
            frontmatter {
              description
              keywords
              tabs
              title
            }
          }
        }
      }
    }
    allTaxonomyTermCategories {
      edges {
        node {
          drupal_id
          name
          field_color
        }
      }
    }
    allTaxonomyTermTags {
      edges {
        node {
          drupal_id
          name
        }
      }
    }
    allNodeQuestion {
      edges {
        node {
          drupal_id
          title
          field_answer {
            processed
          }
          field_question {
            processed
          }
          field_wrong_answers {
            processed
          }
          relationships {
            field_categories {
              drupal_id
            }
            field_keywords {
              drupal_id
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        drupalUrl
      }
    }
  }
`;
